var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"s9"},[_c('div',{staticClass:"swiper-box",attrs:{"data-aos":"zoom-in"}},[_c('swiper',{ref:"swiper",staticClass:"swiper-wrapper",attrs:{"options":_vm.swiperOptions},on:{"slideChangeTransitionStart":_vm.slideChangeTransitionStart}},[(!_vm.isMobile)?_vm._l((_vm.imgs[_vm.imgIdx]['img'].filter(
            (item, index, array) =>
              _vm.imgs[_vm.imgIdx]['mobileOnly'].indexOf(index) == -1
          )),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
            backgroundImage: `url(${slide})`,
          })})}):_vm._l((_vm.imgs[_vm.imgIdx]['img']),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",style:({
            backgroundImage: `url(${slide})`,
          })})})],2),_c('div',{staticClass:"buttons"},_vm._l((_vm.caption),function(item,i){return _c('div',{key:i,staticClass:"btn",class:{ active: _vm.imgIdx == i },on:{"click":function($event){return _vm.swipeTo(i)}}},[_vm._v("\n        "+_vm._s(item.btn)+"\n      ")])}),0),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_vm._m(0),_c('div',{staticClass:"txt"},[_c('div',{staticClass:"title",attrs:{"data-aos":"zoom-in"},domProps:{"innerHTML":_vm._s(_vm.caption[_vm.imgIdx]['title'])}}),_c('div',{staticClass:"desc",attrs:{"data-aos":"zoom-in"},domProps:{"innerHTML":_vm._s(_vm.caption[_vm.imgIdx]['desc'])}})])],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-title",attrs:{"data-aos":"zoom-in"}},[_c('span',[_vm._v("AESTHETICS")])])
}]

export { render, staticRenderFns }