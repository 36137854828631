var render = function render(){var _vm=this,_c=_vm._self._c;return _c('article',{staticClass:"s5"},[_c('div',{staticClass:"swiper-box",attrs:{"data-aos":"zoom-in"}},[_c('swiper',{ref:"swiper",staticClass:"swiper-wrapper",attrs:{"options":_vm.swiperOptions}},_vm._l((_vm.imgs),function(slide,i){return _c('swiper-slide',{key:i,staticClass:"slide",class:{
        'cursor-hover' : _vm.links[i] != ''
      },style:({
        backgroundImage: `url(${slide})`,
      }),nativeOn:{"click":function($event){return _vm.linkTo(i)}}},[_c('p',[_vm._v(_vm._s(_vm.captions[i]))])])}),1),_c('div',{staticClass:"swiper-pagination",attrs:{"slot":"pagination"},slot:"pagination"}),_vm._m(0),_vm._m(1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"large-title",attrs:{"data-aos":"zoom-in"}},[_c('span',[_vm._v("brand")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"txt"},[_c('h3',{staticClass:"title",attrs:{"data-aos":"zoom-in"}},[_vm._v("新日本建築美學"),_c('br'),_vm._v("國際視野職人匠心")]),_c('p',{staticClass:"desc",attrs:{"data-aos":"zoom-in"}},[_vm._v("\n        藏美建設獨鍾新日本建築美學的怦然細膩，屢次親赴日本考察指標建築，轉譯嚴謹的匠心思維，定義「新日本建築美學」基準，以深思熟慮的規劃為經、五感入心的細節為緯，交織富饒生活層次。")])])
}]

export { render, staticRenderFns }